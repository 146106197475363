import { 
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { Stack } from "@mui/material";

function Advertisers() {
    return (
        <div id='advertisers'>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Stack direction={"row"} spacing={10}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Box sx={{maxWidth: 524, marginBottom: "60px"}}>
                            <Typography variant="h3" style={{ textAlign: 'center' }} sx={{fontFamily: "Gilroy-Medium"}} gutterBottom>Promote your Products & Services within AI Apps</Typography>
                        </Box>
                        <Box sx={{maxWidth: 524, marginBottom: "40px"}}>
                            <Typography variant="h6" style={{ textAlign: 'center' }} sx={{fontFamily: "Gilroy-Medium"}} gutterBottom>AI apps & agents will influence how consumers make decisions going forward</Typography>
                        </Box>
                        <Box sx={{maxWidth: 524, marginBottom: "60px"}}>
                            <Typography variant="h6" style={{ textAlign: 'center' }} sx={{fontFamily: "Gilroy-Medium"}} gutterBottom>Join 1,000+ brands & ensure your brand remains relevant with contextual Ad templates to augment answers</Typography>
                        </Box>
                    </Grid>
                    <Stack direction={"column"} spacing={1} sx={{marginTop: "40px"}}>
                        <Box
                            component="img"
                            sx={{
                                height: 360,
                                width: 480,
                                borderRadius: "10px",
                                marginTop: "40px"
                            }}
                            alt='affiliate'
                            src={require("../assets/amazon-associates.png")}
                        />
                        <Box
                            component="img"
                            sx={{
                                height: 360,
                                width: 480,
                                borderRadius: "10px",
                                marginTop: "40px"
                            }}
                            alt='affiliate'
                            src={require("../assets/google-adsense.png")}
                        />
                    </Stack>
                </Stack>
            </Box>
        </div>
    );
}

export default Advertisers;