import { 
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { purple } from '@mui/material/colors';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "#9F2B68",
    '&:hover': {
      backgroundColor: "#AA336A",
    },
}));

function Home() {
  function handleClick() {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSfepdKdEuXAsNS_ydS49fY52Yh6sQOqUsi_R-mePunyvfaFyQ/viewform?usp=sf_link");
  }

  return (
    <div id='home'>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
            style={{
                backgroundImage: "linear-gradient(45DEG, white, #C3B1E1)",
            }}
        >
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Box sx={{marginBottom: "40px"}}>
                    <Typography variant="h3" sx={{fontFamily: "Gilroy-Medium"}} gutterBottom>The Commercial Infrastructure for AI</Typography>
                </Box>
                <Box sx={{marginBottom: "60px"}}>
                    <Typography variant="h6" sx={{fontFamily: "Gilroy-Medium"}} gutterBottom>Augmenting AI Apps & Agents with Contextual Ads</Typography>
                </Box>
                <ColorButton variant="contained" sx={{borderRadius: "10px"}} onClick={handleClick}>
                    Get Access to API
                </ColorButton>
            </Grid>
        </Grid>
    </div>
  );
}

export default Home;