import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
   logo: {
      flexGrow: "1",
      cursor: "pointer",
    },
    link: {
      textDecoration: "none",
      color: "white",
      fontSize: "20px",
      marginLeft: "80px",
      "&:hover": {
        color: "yellow",
        borderBottom: "1px solid white",
      },
    },
  }));

export default function Footer() {
    const classes = useStyles();

    return (
        <Box sx={{
            minHeight: "80px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Typography sx={{marginLeft: "20px"}}>
                © 2024 Optim. All rights reserved.
            </Typography>
            <Link to="/privacy-policy" className={classes.link}>
                <Typography className={classes.logo} variant="h6" sx={{
                    color: "#000",
                    marginRight: "20px"
                }}>
                    Privacy
                </Typography>
            </Link>
        </Box>
    );
}