import { 
    Box,
    Grid,
    Typography,
} from '@mui/material';

function About() {
    return (
        <div id='about'>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Grid container direction="column" justifyContent="center" alignItems="center" style={{
                        backgroundImage: "linear-gradient(45DEG, white, #FAF9F6)",
                    }}>
                    <Box sx={{maxWidth: 500, paddingTop: "10px", marginTop: "20px", fontFamily: "Gilroy-Medium"}}>
                        <Typography variant="h5" gutterBottom>Building the Commercial Infrastructure for AI</Typography>
                    </Box>
                    <Box sx={{
                        maxWidth: 720, 
                        paddingLeft: "20px", 
                        paddingRight: "20px", 
                        marginBottom: "20px", 
                        fontSize: "16px",
                        fontFamily: "Gilroy-Medium"
                    }}>
                        <p>
                        It’s been more than a year since ChatGPT was launched as a ‘low-key’ research project by Open AI & it’s fair to say that we’ll eventually look back upon Nov 2022 as a major inflection point in terms of human technological progress.
                        </p>
                        <p>
                        For decades, when AI was nothing but a utopian promise in the far future, it was commonly agreed that it will disrupt blue-collar work first, followed by knowledge workers & lastly creatives or artists.
                        </p>
                        <p>
                        However, as we’ve seen with the massive adoption of LLM’s like Chat-GPT & AI image generators like MidJourney, that turned out to be true but in reverse.
                        </p>
                        <p>
                        While we haven’t quite made robots reliable enough to replace blue-collar work, but consumers today use AI tools for all kinds of creative and knowledge work, ranging from writing copy & code to designing wire-frames and drafting legal documents. 
                        </p>
                        <p>
                        Most of these apps and tools operate on a subscription model, partly to cover high inference costs associated with AI models & partly because there’s enough value created for consumers to be willing to pay. 
                        </p>
                        <p>
                        However, there are 3 big question marks when it comes to the commercial viability of how AI systems & applications will operate in the future:
                        </p>
                        <ul>
                            <li>As we move away from 10 blue links, what is the business model of ‘Search’ powered by AI?</li>
                            <li>How do creators get compensated for use of copyrighted content in AI models?</li>
                            <li>How do AI Agents interact & transact with external businesses?</li>
                        </ul>
                        <p>We share our thesis on these questions & attempt to solve for it by building <b>Optim AI - The Commercial Infrastructure for AI</b></p>
                        <p style={{fontSize: "20px"}}><b>1) Augmenting AI apps with Contextual Ads</b></p>
                        <p>
                            Over the past year, we’ve seen Google’s dominance in Internet ‘Search’ be threatened by LLM powered applications like ChatGPT & Perplexity AI. While currently these operate on a subscription model and do not offer Ads, our view is that the future business model of such applications is to embrace Ads & enhance user experience, not shy away from it.
                            <br /><br />
                            <b><i>“In a post-scarcity world, trust and attention are the only scarce resources”</i></b>
                            <br /><br />
                            As user attention for getting their questions answered shifts away from blogs & websites to AI apps and personal agents, there needs to be a way for advertisers to contextually target them in this new medium.
                            <br /><br />
                            Google gets a bad rep for prioritising ‘Sponsored Links’ over genuine answers; however, we believe that Ads especially in the right context & format are indistinguishable from quality content and adds value to the user experience.
                            <br /><br />
                            At Optim, we partner with leading brands to optimise & contextualise their Ads for appearing as augmented answers of AI applications. Our unique ‘X’ ensures that the quality of AI powered answers is not compromised because of these Ads and actually enhances the personalised experience a conversational interface has to offer.
                        </p>
                        <p style={{fontSize: "20px"}}><b>2) Enabling Creators to License Content for AI</b></p>
                        <p>
                            We’ve seen major controversy in this space with the New York Times lawsuit filed against Open AI for copyright infringement. While we’re no legal experts, the fact that they also partnered with news publishers like Axel Springer & AP makes it obvious that there is a need for infrastructure which enables licensing of content by creators to AI models & apps at scale.
                            <br /><br />
                            We don’t imagine this to be a trivial problem. With over hundreds of thousands of publishers & bloggers & creators on the Internet, getting each one of them to license content individually & align incentives is a tough challenge.
                            <br /><br />
                            Our belief is that the creators benefit from leaning into having their content shared with AI models, however they should get compensated & attributed for the same fairly. The Internet today is becoming more closed as creators & publishers are skeptical of their data being used by AI models in an unfair manner. We want to change this & we’re building the infrastructure to make this possible
                            <br /><br />
                            If you’re a blogger, news publisher or creator & are wanting your content to appear in answers by AI apps but want to make sure you get relevant attribution & even get paid for it, sign up for our beta test now
                        </p>
                        <p style={{fontSize: "20px"}}><b>3) Optimising businesses to interact with AI Agents</b></p>
                        <p>
                            Ever since demos of AI agents like BabyAGI & AutoGPT became viral last year, we’ve been waiting for a personal assistant to perform mundane tasks for consumers and enterprises. 
                            <br /><br />
                            While the initial demos were nothing more than open-source GitHub projects & cool sped up YouTube videos, we’ve seen actual products and companies built around agents that can reason & execute tasks on behalf of users, using external tools like CRM’s & the web browser.
                            <br /><br />
                            However, most AI agents are still pretty unreliable & fail to get things done more than 50% of the times. If you’ve used any of the web-based AI agents that claim to help users book a cab or order something from Doordash, you know what i’m talking about. 
                            <br /><br />
                            These agents are not well integrated with businesses or websites & are probabilistic, not deterministic in their approach to executing tasks. 
                            <br /><br />
                            We believe there’s going to be an arms race which emerges in the future, wherein users & developers will use more of these agents & bots to get tasks done, while businesses will invest heavily in blocking these unauthenticated agents & bots.
                            <br /><br />
                            At Optim, we eventually want to enable AI agents to seamlessly interact & transact with businesses without being blocked as malicious bots. 
                            <br /><br />
                            While we’re not immediately working on this, if you’re building an AI agent & struggling to have it work on websites or you’re a business wanting to make yourself bot friendly, we’d love to chat!
                        </p>
                    </Box>
                </Grid>
            </Box>
        </div>
    );
}

export default About;