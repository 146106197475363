import React from 'react';
import { 
    Container
} from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <div id='privacy-policy'>
        <Container>
            <h2>Privacy Policy</h2>
            <div className='lastUpdated'>
                <b>Last updated:</b> 23/02/2024
            </div>
            <div>
                <p>
                Optim values the privacy of individuals
                who use our websites, actions or services that link to
                this Privacy Policy (collectively, our “<b>Services</b>”). This
                privacy policy (the “<b>Privacy Policy</b>”) explains how we
                collect, use, and disclose personal information from users of our
                Services. By using our Services, you agree to the collection, use,
                and disclosure of your personal information as described in this
                Privacy Policy. Beyond this Privacy Policy, your use of our Services
                is also subject to our Terms of Service.
                </p>
                <h3>
                INFORMATION WE COLLECT
                </h3>
                <p>
                We may collect a variety of information from or about you or your
                devices from various sources, as described below.
                </p>
                <p>
                If you do not provide your information when requested, you may not
                be able to use our Services if that information is necessary to
                provide you with our Services or if we are legally required to
                collect it.
                </p>
                <h3>
                <b>A. Information You Provide to Us</b>
                </h3>
                <p>
                <b>Registration.</b> When you register for an account to use our
                Services, we ask you for your personal information, including your
                first name, last name, profile picture, company information,
                specialization, phone number, email address, and password. When we
                send you emails, we may track whether you open them to learn how to
                deliver a better customer experience and improve our Services.
                </p>
                <p>
                <b>Request a Demo.</b> If you schedule a demo of our Services, we
                will collect your personal information, including your email address
                and any additional information you choose to provide.
                </p>
                <p>
                <b>Communications.</b> If you contact us directly, we may receive
                additional personal information about you. For example, when you
                contact us for support, we will receive your first name, last name,
                email address, company information, and phone number. If you
                subscribe to our newsletter, we will receive your name and email
                address.
                </p>
                <p>
                <b>Uploaded Information.</b> When you use our Services, we may
                receive personal information you choose to provide to us, including
                survey results.
                </p>
                <p>
                <b>Camera Access.</b> When you use our Services, we may receive
                access to the camera on your phone or other device to provide our
                Services.
                </p>
                <p>
                <b>Payment Information.</b> When you make a payment to us, your
                credit card information, debit card information, billing
                information, and any other financial information necessary to
                complete your payment (“Payment Information”) is processed by our
                third-party payment processor (Stripe), and we do not collect,
                store, or process your Payment Information. For more information,
                please see the Payment Processing section below.
                </p>
                <p>
                <b>Careers.</b> If you decide to apply for a job with us, you may
                submit your contact information and your resume online. We will
                collect the personal information you choose to provide us as part of
                your job application, such as your contact information, current
                employment information, and other information you choose to submit
                with your application and on your resume. If you apply for a job
                with us through a third-party platform (such as Glassdoor or
                LinkedIn), we will collect the information you make available to us
                through such third-party platform.
                </p>
                <h3>
                <b>B. Information We Collect When You Use Our Services</b>
                </h3>
                <p>
                <b>Location Information.</b> When you use our Services, we may
                receive infer your general location information. For example, your
                IP address may indicate your general geographic region.
                </p>
                <p>
                <b>Device Information.</b> We receive information about the device
                and software you use to access our Services, including internet
                protocol (IP) address, web browser type, operating system version,
                and device identifiers.
                </p>
                <p>
                <b>Usage Information.</b> To help us understand how you use our
                Services and to help us improve them, we automatically receive
                information about your interactions with our Services, such as the
                pages or other content you view, the searches you conduct, telemetry
                data, and the dates and times of your visits.
                </p>
                <p>
                <b>Analytics Partners.</b> We may use analytics services such as
                Google Analytics to collect and process certain analytics data.
                These services may also collect information about your use of other
                websites, apps, and online resources. You can learn about Google’s
                practices by going to 
                <a href="https://www.google.com/policies/privacy/partners/">
                    https://www.google.com/policies/privacy/partners/
                </a>{' '}
                and opt out of them by downloading the Google Analytics Opt-out
                Browser Add-on, available at 
                <a href="https://tools.google.com/dlpage/gaoptout">
                    https://tools.google.com/dlpage/gaoptout
                </a>
                .
                </p>
                <p>
                <b>Information from Cookies and Similar Technologies.</b> We and our
                third-party partners may collect information using cookies, pixel
                tags, or similar technologies. Our third-party partners, such as
                analytics and advertising partners, may use these technologies to
                collect information about your online activities over time and
                across different services. Cookies are small text files containing a
                string of alphanumeric characters. We may use both session cookies
                and persistent cookies. A session cookie disappears after you close
                your browser. A persistent cookie remains after you close your
                browser and may be used by your browser on subsequent visits to our
                Services.
                </p>
                <p>
                Please review your web browser's “Help” file to learn the proper way
                to modify your cookie settings. Please note that if you delete or
                choose not to accept cookies from our Services, you may not be able
                to utilize the features of our Services to their fullest potential.
                </p>
                <h3>
                <b>C. Payment Processing</b>
                </h3>
                <p>
                When you make purchases through our Services, your payment is
                processed by a third-party payment processor such as Stripe (“
                <b>Payment Processor</b>”). This Payment Processor may collect
                Payment Information and other information from you, including your
                name, email address, and billing address. The use of your
                information by the Payment Processor is governed by its terms and
                conditions and privacy policy, and information we receive about you
                from our Payment Processor is governed by this Privacy Policy.
                </p>
                <h3>
                <b>D. Information We Receive from Third Parties</b>
                </h3>
                <p>
                <b>Platform Sign-On.</b> When you register for our Services using a
                third-party account such as your Google, Facebook or Apple account,
                we receive your personal information, including your name, phone
                number and email address.
                </p>
                <h3>
                HOW WE USE THE INFORMATION WE COLLECT
                </h3>
                <p>
                We use the information we collect:
                </p>
                <ul>
                <li>
                    To provide, maintain, debug, improve, and enhance our Services;
                </li>
                <li>
                    To understand and analyze how you use our Services, and develop
                    new products, services, features, and functionalities;
                </li>
                <li>
                    To understand and analyze usage patterns;
                </li>
                <li>
                    To process your job application;
                </li>
                <li>
                    To send you SMS messages and push notifications;
                </li>
                <li>
                    To communicate with you, provide you with updates and other
                    information relating to our Services, provide information that you
                    request, respond to comments and questions, and otherwise provide
                    customer support;
                </li>
                <li>
                    To find and prevent fraud, and respond to trust and safety issues
                    that may arise;
                </li>
                <li>
                    For compliance purposes, including enforcing our Terms of Service
                    or other legal rights, or as may be required by applicable laws
                    and regulations or requested by any judicial process or
                    governmental agency; and
                </li>
                <li>
                    For other purposes for which we provide specific notice at the
                    time the information is collected.
                </li>
                </ul>
                <h3>
                HOW WE SHARE THE INFORMATION WE COLLECT
                </h3>
                <p>
                We do not share or otherwise disclose information we collect from
                you except as described below or otherwise disclosed to you at the
                time of the collection.
                </p>
                <p>
                <b>Affiliates.</b> We may share any information we receive with our
                affiliates for any of the purposes described in this Privacy Policy.
                </p>
                <p>
                <b>Vendors and Service Providers.</b> We may share any information
                we receive with vendors and service providers retained in connection
                with the provision of our Services.
                </p>
                <p>
                <b>Service Provider Content.</b> Our Services allow providers to
                share content with other users. As a service provider, your first
                name, last name, company information, specialization, and other
                profile information will always be viewable and searchable by other
                users. The content you post to our Services will be displayed on our
                Services and viewable by other users by default. Although this
                content is public by default, below in the “Your Choices” section of
                this Privacy Policy, we describe the controls that you can use,
                along with other relevant settings associated with your account, to
                limit the sharing of certain information. We are not responsible for
                the other users’ use of available information, so you should
                carefully consider whether and what to post or how you identify
                yourself on our Services.
                </p>
                <p>
                <b>Marketing.</b> With your consent, we may share your information
                with third parties in connection with challenges and other
                promotional offerings. We do not rent, sell, or share information
                about you with nonaffiliated companies for their direct marketing
                purposes unless we have your permission.
                </p>
                <p>
                <b>As Required by Law and Similar Disclosures.</b> We may access,
                preserve, and disclose your personal information if we believe doing
                so is required or appropriate to: (a) comply with law enforcement
                requests and legal process, such as a court order or subpoena; (b)
                respond to your requests; or (c) protect your, our, or others’
                rights, property, or safety.
                </p>
                <p>
                <b>Merger, Sale, or Other Asset Transfers.</b> We may disclose and
                transfer your personal information to service providers, advisors,
                potential transactional partners, or other third parties in
                connection with the consideration, negotiation, or completion of a
                corporate transaction in which we are acquired by or merged with
                another company or we sell, liquidate, or transfer all or a portion
                of our business or assets.
                </p>
                <p>
                <b>Consent.</b> We may also disclose personal information from or
                about you or your devices with your permission.
                </p>
                <h3>YOUR CHOICES</h3>
                <p>
                <b>Marketing Communications.</b> You can unsubscribe from our
                promotional emails via the link provided in the emails. Even if you
                opt out of receiving promotional messages from us, you will continue
                to receive administrative messages from us.
                </p>
                <p>
                <b>Sharing Preferences.</b> We provide you with settings to allow
                you to set your sharing preferences for content you post to our
                Services.
                </p>
                <p>
                <b>Do Not Track.</b> There is no accepted standard on how to respond
                to Do Not Track signals, and we do not respond to such signals.
                </p>
                <p>
                If you choose not to provide us with the information we collect,
                some features of our Services may not work as intended.
                </p>
                <h3>THIRD PARTIES</h3>
                <p>
                Our Services may contain links to other websites, products, or
                services that we do not own or operate (“Third-Party Services”). We
                are not responsible for the privacy practices of these Third-Party
                Services. Please be aware that this Privacy Policy does not apply to
                your activities on these Third-Party Services or any information you
                disclose to these Third-Party Services. We encourage you to read the
                privacy policies of these Third-Party Services.
                </p>
                <h3>SECURITY</h3>
                <p>
                We make reasonable efforts to protect your personal information by
                using physical and electronic safeguards designed to improve the
                security of the personal information we maintain. However, as no
                electronic transmission or storage of information can be entirely
                secure, we can make no guarantees as to the security or privacy of
                your personal information.
                </p>
                <h3>
                INTERNATIONAL VISITORS
                </h3>
                <p>
                Our Services are hosted in the United States (“<b>U.S.</b>”) and
                intended for visitors located within the U.S. If you choose to use
                our Services from the European Union or other regions of the world
                with laws governing data collection and use that may differ from
                U.S. law, then please note that you are transferring your personal
                information outside of those regions to the U.S. for storage and
                processing. Also, we may transfer your data from the U.S. to other
                countries or regions in connection with storage and processing of
                data, fulfilling your requests, and operating our Services. By
                providing any information, including personal information, on or to
                our Services, you consent to such transfer, storage, and processing.
                </p>
                <h3>
                CHILDREN’S PRIVACY
                </h3>
                <p>
                We do not knowingly collect, maintain, or use personal information
                from children under 13 years of age, and no parts of our Services
                are directed to children. If you learn that a child has provided us
                with personal information in violation of this Privacy Policy,
                please alert us.
                </p>
                <h3>
                CHANGES TO THIS PRIVACY POLICY
                </h3>
                <p>
                We will post any adjustments to the Privacy Policy on this page, and
                the revised version will be effective when it is posted. If we
                materially change the ways in which we use or share personal
                information previously collected from you through our Services, we
                will attempt to notify you through our Services, by email, or by
                other means.
                </p>
                <h3>
                CONTACT INFORMATION
                </h3>
                <p>
                If you have any questions, comments, or concerns about our
                processing activities, please email us at{' '}
                <a href="mailto:vihang797@gmail.com">vihang797@gmail.com</a>.
                </p>
            </div>
          </Container>
    </div>
  );
};

export default PrivacyPolicyPage;