import React from "react";
import { 
    AppBar,
    Toolbar,
    CssBaseline,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HashLink } from 'react-router-hash-link';
import DrawerComponent from "./DrawerComponent";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: "100vh",
    display: "flex",
  },
  mainLogo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    "&:hover": {
        maxWidth: "100% !important",
        margin: 0,
        backgroundColor: "#F9F6EE",
        borderRadius: "20px",
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: "80px",
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

function NavBar() {
    const classes = useStyles();

    const [width, setWidth] = React.useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <AppBar position="static" style={{
                zIndex: 20,
                background: "#FFF",
                top: 0,
                left: 0,
                right: 0,
                transition: "background 0.5s",
            }}>
            <CssBaseline />
            <Toolbar>
            <HashLink smooth to="/#home" className={classes.link}>
                <Typography align="center" className={classes.mainLogo} variant="h4"  sx={{
                    color: "#000"
                }}>
                    Optim AI
                </Typography>
            </HashLink>
            {isMobile 
                ? (<DrawerComponent></DrawerComponent>)
                : (<div className={classes.navlinks}>
                    <HashLink smooth to="/#creators" className={classes.link}>
                        <Typography align="center" className={classes.logo} variant="h6" sx={{
                            color: "#000"
                        }}>
                            AI Apps
                        </Typography>
                    </HashLink>
                    <HashLink smooth to="/#advertisers" className={classes.link}>
                        <Typography align="center" className={classes.logo} variant="h6" sx={{
                            color: "#000"
                        }}>
                            Advertisers
                        </Typography>
                    </HashLink>
                    <HashLink smooth to="/#about" className={classes.link}>
                        <Typography align="center" className={classes.logo} variant="h6" sx={{
                            color: "#000"
                        }}>
                            About Us
                        </Typography>
                    </HashLink>
                </div>
            )}
            </Toolbar>
        </AppBar>
    );
}

export default NavBar;