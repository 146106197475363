import { 
    Box,
    Grid,
    Typography,
    Stack
} from '@mui/material';

function Creators() {
    return (
        <div id='creators'>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Stack direction={"row"} spacing={10}>
                    <Box
                        component="img"
                        sx={{
                            height: 512,
                            width: 840,
                            marginTop: "40px"
                        }}
                        alt='affiliate'
                        src={require("../assets/optim-output.png")}
                    />
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Box sx={{maxWidth: 524, marginBottom: "40px"}}>
                            <Typography variant="h3" style={{ textAlign: 'center' }} sx={{fontFamily: "Gilroy-Medium"}} gutterBottom>Monetize your AI Apps & Agents with Contextual Ads</Typography>
                        </Box>
                        <Box sx={{maxWidth: 524, marginBottom: "40px"}}>
                            <Typography variant="h6" style={{ textAlign: 'center' }} sx={{fontFamily: "Gilroy-Medium"}} gutterBottom>Augment your AI responses with Contextual Ads served from 1,000+ leading brands on our network</Typography>
                        </Box>
                    </Grid>
                </Stack>
            </Box>
        </div>
    );
}

export default Creators;