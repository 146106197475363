import React from 'react';
import Home from "./pages/home";
import About from "./pages/about";
import Creators from "./pages/creators";
import Advertisers from "./pages/advertisers";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import './App.css';
import PrivacyPolicyPage from './pages/privacy-policy';
import Footer from './components/Footer';
import ScrollToTop from './utils/ScrollToTop';

class LandingPage extends React.Component {
    render() {
      return (
        <React.Fragment>
          <Home />
          <Creators />
          <Advertisers />
          <About />
        </React.Fragment>
      );
    }
}

function App() {
    return (
      <Router>
        <NavBar />
        <ScrollToTop />
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
        <Footer/>
      </Router>
    );
}

export default App;
